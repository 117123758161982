import React from 'react'
import UpTech from './UpTech_logo.svg'
import './App.css'
import footer from './components/footer'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={UpTech} className="App-logo" alt="UpTech" />

        <p>hello@uptech.com.au</p>
      </header>

      {footer}
      <footer />
    </div>
  )
}

export default App
